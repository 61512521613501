import React from 'react';

import selectorProps from 'propTypes/Selector/selectorProps';

import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Close } from './close.svg';

function GlobalSvgSelector({ id, ...props }) {
  switch (id) {
  case 'delete':
    return (<Delete {...props} />);
  case 'edit':
    return (<Edit {...props} />);
  case 'close':
    return (<Close {...props} />);

  default:
    return null;
  }
}

GlobalSvgSelector.propTypes = selectorProps;

export default React.memo(GlobalSvgSelector);
