import React from 'react';

import Title from 'components/UI/Title/Title';
import useImages from 'hooks/useImages';

import Context from 'context';

import { useScroll, useTransform, motion } from 'framer-motion';
import styles from './About.module.scss';

function About() {
  const images = {
    bgCircles: {
      webp: import('assets/images/hero/webp/bg-circles.webp'),
      png: import('assets/images/hero/png/bg-circles.png')
    },
    branch: {
      webp: import('assets/images/about/webp/branch.webp'),
      png: import('assets/images/about/png/branch.png')
    },
    stone: {
      webp: import('assets/images/about/webp/stone.webp'),
      png: import('assets/images/about/png/stone.png')
    }
  };
  const { webp } = React.useContext(Context);
  const { imageSrcs, activateImage } = useImages(images, webp);
  const imgStyleOpacity = { opacity: activateImage ? 1 : 0 };
  const imgBranchStyle = { opacity: activateImage ? 0.2 : 0 };

  const { scrollYProgress } = useScroll({
    offset: ['start end', 'end end']
  });

  const windowWidth = window.innerWidth;
  const yRightOutputRange = windowWidth > 1440 ? [-300, 200] : windowWidth > 1024 ? [-400, 50] : windowWidth > 768 ? [-400, -100] : [-300, 0];
  const yRightInputRange = windowWidth > 1440 ? [0.1, 0.25] : windowWidth > 768 ? [0.1, 0.25] : [0.1, 0.2];
  const opacityRightInputRange = windowWidth > 1440 ? [0.1, 0.25, 0.3, 0.45] : windowWidth > 768 ? [0.1, 0.25, 0.3, 0.45] : [0.1, 0.2, 0.25, 0.4];
  const xInputRange = windowWidth > 1440 ? [0.3, 0.45] : windowWidth > 768 ? [0.3, 0.5] : [0.25, 0.5];

  const ySmallStoneOutputRange = windowWidth > 1440 ? [400, 650] : windowWidth > 1024 ? [400, 700] : windowWidth > 768 ? [400, 650] : [0, 200];
  const xSmallStoneOutputRange = windowWidth > 1440 ? [600, 100] : windowWidth > 1024 ? [700, 0] : windowWidth > 768 ? [700, 0] : [-300, 300];
  const rotateSmallStoneOutputRange = windowWidth > 768 ? ['45deg', '-240deg'] : ['-45deg', '320deg'];

  const yRight = useTransform(scrollYProgress, yRightInputRange, yRightOutputRange);
  const yLargeStone = useTransform(scrollYProgress, [0.1, 0.4], [600, 0]);
  const xLargeStone = useTransform(scrollYProgress, [0.1, 0.4], [0, -400]);
  const yMediumStone = useTransform(scrollYProgress, [0.1, 0.3], [600, -400]);
  const ySmallStone = useTransform(scrollYProgress, [0.1, 0.3], ySmallStoneOutputRange);
  const xSmallStone = useTransform(scrollYProgress, [0.3, 0.5], xSmallStoneOutputRange);
  const scaleSmallStone = useTransform(scrollYProgress, [0.3, 0.5], [1, 2]);
  const rotateSmallStone = useTransform(scrollYProgress, [0.3, 0.5], rotateSmallStoneOutputRange);
  const x = useTransform(scrollYProgress, xInputRange, [0, 700]);
  const opacityRight = useTransform(scrollYProgress, opacityRightInputRange, [0, 1, 1, 0]);

  return (
    <div className={styles.about} id="about">
      <img className={styles.branch} src={imageSrcs.branch} alt="branch" loading="lazy" style={imgBranchStyle} />
      <motion.img
        className={styles.stone}
        id={styles['large-stone']}
        src={imageSrcs.stone}
        alt="large stone"
        loading="lazy"
        style={{
          y: yLargeStone,
          x: xLargeStone,
          opacity: activateImage ? 1 : 0
        }}
      />
      <motion.img
        className={styles.stone}
        id={styles['medium-stone']}
        src={imageSrcs.stone}
        alt="medium stone"
        loading="lazy"
        style={{
          y: yMediumStone,
          opacity: activateImage ? 1 : 0
        }}
      />
      <motion.img
        className={styles.stone}
        id={styles['small-stone']}
        src={imageSrcs.stone}
        alt="small stone"
        loading="lazy"
        style={{
          x: xSmallStone,
          y: ySmallStone,
          scale: scaleSmallStone,
          rotate: rotateSmallStone,
          opacity: activateImage ? 1 : 0
        }}
      />
      <div className={styles.right}>
        <img className={styles.circles} src={imageSrcs.bgCircles} alt="background circles" style={imgStyleOpacity} />
        <motion.div className={styles['right-text-block']} style={{ x, y: yRight, opacity: opacityRight }}>
          <p className={styles['pre-title']}>About NoctuaDomain production</p>
          <Title customClassName={styles.title}>&quot;Let&apos;s bring your ideas  to Life&quot;</Title>
          <div className={styles['desc-wrapper']}>
            <p className={styles.desc}>3d animation and design studio, a team of professionals, provides state-of-the-art, dynamic product and engineering design services, that are tailor made to your needs. So lets bring your ideas to life!</p>
            <p className={styles.desc}>We carry out a full cycle of creating animated content from creating an idea to rendering the final video. 2D artists will create concepts for the future project, afterwards 3D artists will bring it to life. The project manager will assist you with timelines, editing and production control throughout the whole project.</p>
            <p className={styles.desc}>Thus, your task is only to trust our great experience and enjoy the process of creating. Rest assured that our CG team of experts can convey your creative vision with exclusive digital art. Share with us your thoughts and we will start building your story today.</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default About;
