import React from 'react';

import selectorProps from 'propTypes/Selector/selectorProps';

import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Youtube } from './youtube.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Phone } from './phone.svg';

function FooterSvgSelector({ id, ...props }) {
  switch (id) {
  case 'instagram':
    return (<Instagram {...props} />);
  case 'youtube':
    return (<Youtube {...props} />);
  case 'mail':
    return (<Mail {...props} />);
  case 'phone':
    return (<Phone {...props} />);

  default:
    return null;
  }
}

FooterSvgSelector.propTypes = selectorProps;

export default React.memo(FooterSvgSelector);
