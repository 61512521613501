import React from 'react';

import selectorProps from 'propTypes/Selector/selectorProps';

import { ReactComponent as Mapping3D } from './3d-mapping.svg';
import { ReactComponent as CharAndEnv } from './characters-and-environment.svg';
import { ReactComponent as Animations3D2D } from './3d-2d-animations.svg';
import { ReactComponent as VFX } from './vfx.svg';
import { ReactComponent as MotionGraphics } from './motion-graphics.svg';
import { ReactComponent as VideoShooting } from './video-shooting.svg';
import { ReactComponent as ArVr } from './ar-vr.svg';
import { ReactComponent as Performance } from './performance.svg';

function ServicesSvgSelector({ id, ...props }) {
  switch (id) {
  case '3d-mapping':
    return (<Mapping3D {...props} />);
  case 'characters-and-environment':
    return (<CharAndEnv {...props} />);
  case '3d-2d-animations':
    return (<Animations3D2D {...props} />);
  case 'vfx':
    return (<VFX {...props} />);
  case 'motion-graphics':
    return (<MotionGraphics {...props} />);
  case 'video-shooting':
    return (<VideoShooting {...props} />);
  case 'ar-vr':
    return (<ArVr {...props} />);
  case 'performance':
    return (<Performance {...props} />);

  default:
    return null;
  }
}

ServicesSvgSelector.propTypes = selectorProps;

export default React.memo(ServicesSvgSelector);
