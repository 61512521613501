import React from 'react';

import Title from 'components/UI/Title/Title';
import ContactForm from 'components/elements/ContactForm/ContactForm';

import Context from 'context';
import useImages from 'hooks/useImages';
import styles from './Contact.module.scss';

function Contact() {
  const images = {
    bgLinesVertical: {
      webp: import('assets/images/contact/webp/horizontal-lines.webp'),
      png: import('assets/images/contact/png/horizontal-lines.png')
    },
    owlDetail: {
      webp: import('assets/images/contact/webp/owl-detail.webp'),
      png: import('assets/images/contact/png/owl-detail.png')
    },
    owl: {
      webp: import('assets/images/contact/webp/owl.webp'),
      png: import('assets/images/contact/png/owl.png')
    }
  };
  const { webp } = React.useContext(Context);
  const { imageSrcs, activateImage } = useImages(images, webp);
  const imgStyleOpacity = { opacity: activateImage ? 1 : 0 };

  return (
    <div className={styles.contact} id="contact">
      <img className={styles['bg-lines']} src={imageSrcs.bgLinesVertical} alt="background lines" loading="lazy" style={imgStyleOpacity} />
      <img className={styles['owl-detail']} src={imageSrcs.owlDetail} alt="background owl detail" loading="lazy" style={imgStyleOpacity} />
      <img className={styles.owl} src={imageSrcs.owl} alt="background owl" loading="lazy" style={imgStyleOpacity} />
      <Title customClassName={styles.title}>Contact Us</Title>
      <ContactForm />
    </div>
  );
}

export default Contact;
