import React from 'react';

import RoadmapMobile from 'components/elements/RoadmapMobile/RoadmapMobile';
import RoadmapPC from 'components/elements/RoadmapPC/RoadmapPC';

import { mobileWidth } from 'configs/config';

function Roadmap() {
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= parseInt(mobileWidth, 10)) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    isMobile ? <RoadmapMobile /> : <RoadmapPC />
  );
}

export default Roadmap;
