import React from 'react';

import Context from 'context';
import useImages from 'hooks/useImages';
import styles from './StickySocials.module.scss';

function StickySocials() {
  const images = {
    whatsapp: {
      webp: import('assets/images/global/webp/whatsapp.webp'),
      png: import('assets/images/global/png/whatsapp.png')
    },
    telegram: {
      webp: import('assets/images/global/webp/telegram.webp'),
      png: import('assets/images/global/png/telegram.png')
    }
  };

  const webp = React.useContext(Context);
  const { imageSrcs, activateImage } = useImages(images, webp);

  const imgStyleOpacity = { opacity: activateImage ? 1 : 0 };

  const TG_ID = '+971505637748';
  const PHONE_NUMBER = '971505637748';

  const telegramHref = `https://t.me/${TG_ID}`;
  const whatsAppHref = `https://wa.me/${PHONE_NUMBER}`;

  return (
    <div className={styles['socials-area-wrapper']}>
      <div className={styles['socials-area']}>
        <div className={styles['socials-panel']}>
          <div className={styles.socials}>
            <a className={styles.element} href={telegramHref} target="_blank" rel="noreferrer">
              <img src={imageSrcs.telegram} alt="telegram icon" style={imgStyleOpacity} />
            </a>
            <a className={styles.element} href={whatsAppHref} target="_blank" rel="noreferrer">
              <img src={imageSrcs.whatsapp} alt="whatsapp icon" style={imgStyleOpacity} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StickySocials;
