import React from 'react';

import formButtonProps from 'propTypes/FormButton/formButtonProps';
import formButtonDefaultProps from 'propTypes/FormButton/formButtonDefaultProps';

import styles from './FormButton.module.scss';

function FormButton({ children, customClassName }) {
  const buttonStyles = !customClassName ? styles.button : `${styles.button} ${customClassName}`;

  return (
    <button type="submit" className={buttonStyles}>
      {children}
    </button>
  );
}

FormButton.propTypes = formButtonProps;
FormButton.defaultPropTypes = formButtonDefaultProps;

export default FormButton;
