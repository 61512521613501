import React from 'react';

import Title from 'components/UI/Title/Title';
import PortfolioCarouselContainer from 'containers/elements/PortfolioCarousel/PortfolioCarousel';

import Context from 'context';

import useImages from 'hooks/useImages';

import { mobileWidth } from 'configs/config';

import { motion, useScroll, useTransform } from 'framer-motion';
import styles from './Portfolio.module.scss';

function Portfolio() {
  const images = {
    bgLinesHorizontal: {
      webp: import('assets/images/portfolio/webp/bg-lines-horizontal.webp'),
      png: import('assets/images/portfolio/png/bg-lines-horizontal.png')
    },
    bgLinesVertical: {
      webp: import('assets/images/portfolio/webp/bg-lines-vertical.webp'),
      png: import('assets/images/portfolio/png/bg-lines-vertical.png')
    },
    stone: {
      webp: import('assets/images/about/webp/stone.webp'),
      png: import('assets/images/about/png/stone.png')
    }
  };
  const { webp } = React.useContext(Context);
  const { imageSrcs, activateImage } = useImages(images, webp);
  const imgStyleOpacity = { opacity: activateImage ? 1 : 0 };

  const ref = React.useRef(null);
  /* const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end']
  }); */
  const { scrollY } = useScroll({
    offset: ['start end', 'end end']
  });

  window.addEventListener('scroll', () => {
    console.log(scrollY.current);
  });

  const windowWidth = window.innerWidth;
  const yStoneOutputRange = windowWidth > mobileWidth ? [-1000, -1000] : [0, -400];
  const xStoneOutputRange = windowWidth > 1440 ? [-1600, -300] : windowWidth > 1024 ? [-1000, 0] : [-100, 600];
  const rotateStoneOutputRange = windowWidth > mobileWidth ? ['0deg', '180deg'] : ['0deg', '180deg'];
  const stoneInputRange = windowWidth > 1440 ? [1800, 3500] : windowWidth > 1024 ? [1500, 3500] : windowWidth > 768 ? [1400, 3300] : [1900, 4000];

  const yStone = useTransform(scrollY, stoneInputRange, yStoneOutputRange);
  const xStone = useTransform(scrollY, stoneInputRange, xStoneOutputRange);
  const rotateStone = useTransform(scrollY, stoneInputRange, rotateStoneOutputRange);

  /* const ySmallStoneOutputRange = windowWidth > 1440 ? [0, 650] : windowWidth >= (mobileWidth - 100) ? [-200, -500] : [-200, -500];
  const xSmallStoneOutputRange = windowWidth > 1440 ? [700, 100] : windowWidth >= (mobileWidth - 100) ? [-500, 700] : [0, 600];

  const ySmallStone = useTransform(scrollYProgress, [0, 1], ySmallStoneOutputRange);
  const xSmallStone = useTransform(scrollYProgress, [0, 1], xSmallStoneOutputRange); */

  return (
    <div className={styles.portfolio} id="portfolio" ref={ref}>
      <img className={styles['bg-lines-horizontal']} src={imageSrcs.bgLinesHorizontal} alt="background lines horizontal" loading="lazy" style={imgStyleOpacity} />
      <img className={styles['bg-lines-vertical']} src={imageSrcs.bgLinesVertical} alt="background lines vertical" loading="lazy" style={imgStyleOpacity} />
      <motion.img
        className={styles.stone}
        id={styles['large-stone']}
        src={imageSrcs.stone}
        alt="large stone"
        loading="lazy"
        style={{
          x: xStone,
          y: yStone,
          rotate: rotateStone,
          opacity: activateImage ? 1 : 0
        }}
      />
      {/* <motion.img
        className={styles.stone}
        id={styles['small-stone']}
        src={imageSrcs.stone}
        alt="small stone"
        loading="lazy"
        style={{
          x: xSmallStone,
          y: ySmallStone,
          opacity: activateImage ? 1 : 0
        }}
      /> */}
      <div className={styles['title-wrapper']}>
        <Title>Portfolio Section:</Title>
        <p className={styles.desc}>our projects</p>
      </div>
      <PortfolioCarouselContainer />
    </div>
  );
}

export default Portfolio;
