import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as FirstLine } from './first-line.svg';
import { ReactComponent as SecondLine } from './second-line.svg';
import { ReactComponent as ThirdLine } from './third-line.svg';
import { ReactComponent as FourthLine } from './fourth-line.svg';
import { ReactComponent as FifthLine } from './fifth-line.svg';

const RoadmapSvgSelector = React.forwardRef(({ iconId, ...props }, ref) => {
  switch (iconId) {
  case 'first-line':
    return (<FirstLine ref={ref} {...props} />);
  case 'second-line':
    return (<SecondLine ref={ref} {...props} />);
  case 'third-line':
    return (<ThirdLine ref={ref} {...props} />);
  case 'fourth-line':
    return (<FourthLine ref={ref} {...props} />);
  case 'fifth-line':
    return (<FifthLine ref={ref} {...props} />);

  default:
    return null;
  }
});

RoadmapSvgSelector.propTypes = {
  iconId: PropTypes.string.isRequired
};

export default RoadmapSvgSelector;
