import React from 'react';

/* eslint-disable guard-for-in,no-restricted-syntax */

const useImages = (images, webp) => {
  const [imageSrcs, setImageSrcs] = React.useState({});
  const [activateImage, setActivateImage] = React.useState(false);

  React.useEffect(() => {
    const promises = {};
    for (const key in images) {
      promises[key] = webp ? images[key].webp : images[key].png;
    }
    Promise.all(Object.values(promises)).then((modules) => {
      const urls = {};
      for (const key in promises) {
        const index = Object.keys(promises).indexOf(key);
        urls[key] = modules[index].default;
      }
      setImageSrcs(urls);
    });
  }, [webp]);

  React.useEffect(() => {
    if (Object.keys(imageSrcs).length !== 0) setActivateImage(true);
  }, [imageSrcs]);

  return { imageSrcs, activateImage };
};

export default useImages;
