import React from 'react';

import titleProps from 'propTypes/Title/titleProps';
import titleDefaultProps from 'propTypes/Title/titleDefaultProps';
import styles from './Title.module.scss';

function Title({ children, customClassName }) {
  const titleStyles = !customClassName ? styles.title : `${styles.title} ${customClassName}`;

  return (
    <p className={titleStyles}>
      {children}
    </p>
  );
}

Title.propTypes = titleProps;
Title.defaultPropTypes = titleDefaultProps;

export default React.memo(Title);
