import React from 'react';

import { Field } from 'formik';

import formikFieldProps from 'propTypes/FormikField/formikFieldProps';

import styles from './FormikField.module.scss';

function FormikField({
  name, type, placeholder, title, textarea
}) {
  const fieldStyles = textarea
    ? styles['multi-field']
    : styles['single-field'];

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <div className={fieldStyles}>
          <p className={styles.title}>
            {title}
          </p>
          {textarea
            ? <textarea className={styles.textarea} {...field} placeholder={placeholder} />
            : <input type={type} {...field} className={styles.input} placeholder={placeholder} />}
          {meta.touched && meta.error && (
            <div className={styles.error}>{meta.error}</div>)}
        </div>
      )}
    </Field>
  );
}

FormikField.propTypes = formikFieldProps;

export default FormikField;
