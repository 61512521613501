import React from 'react';

const getPathStyle = (index, pathLength, scrollPercentage) => {
  const COEFS = [1.5, -1.5, 1, -1, 1];
  const BOUNDS = [0.1, 0.51, 0.69, 0.82, 0.82];

  let drawLength;
  if (index === 0) {
    drawLength = (pathLength * 1.6) * (scrollPercentage - BOUNDS[0]) * COEFS[0];
  } else if (index === 1) {
    drawLength = (pathLength * 2.5) * (scrollPercentage - BOUNDS[1]) * COEFS[1];
  } else if (index === 2) {
    drawLength = (pathLength * 5) * (scrollPercentage - BOUNDS[2]) * COEFS[2];
  } else if (index === 3) {
    drawLength = (pathLength * 6) * (scrollPercentage - BOUNDS[3]) * COEFS[3];
  } else if (index === 4) {
    drawLength = (pathLength * 6) * (scrollPercentage - BOUNDS[4]) * COEFS[4];
  }

  let offset;
  if (index === 0) {
    offset = drawLength < 0 || drawLength > pathLength ? 0 : pathLength - drawLength;
  } else if (index === 1) {
    offset = drawLength < -pathLength ? 0 : drawLength > 0 ? pathLength : pathLength - drawLength;
  } else if (index === 2) {
    offset = drawLength > pathLength ? 0 : drawLength < 0 ? pathLength : pathLength - drawLength;
  } else if (index === 3) {
    offset = drawLength < -pathLength ? 0 : drawLength > 0 ? pathLength : pathLength - drawLength;
  } else if (index === 4) {
    offset = drawLength > pathLength ? 0 : drawLength < 0 ? pathLength : pathLength - drawLength;
  }

  return {
    strokeDasharray: pathLength,
    strokeDashoffset: offset
  };
};

const useScrollPaths = (refLines, scrollYProgress) => {
  const paths = React.useRef([]);

  React.useEffect(() => {
    paths.current = refLines.map((ref) => ref.current.getElementsByTagName('path')[0]);
  }, [refLines]);

  const handleScroll = React.useCallback(() => {
    const scrollPercentage = scrollYProgress.current;

    if (paths.current.length !== 0) {
      for (let i = 0; i < paths.current.length; i++) {
        const pathLength = paths.current[i].getTotalLength();

        const pathStyle = getPathStyle(i, pathLength, scrollPercentage);

        paths.current[i].style.strokeDasharray = pathStyle.strokeDasharray;
        paths.current[i].style.strokeDashoffset = pathStyle.strokeDashoffset;
      }
    }
  }, [paths, scrollYProgress]);

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, paths]);
};

export default useScrollPaths;
