import React from 'react';

import PortfolioButton from 'components/UI/PortfolioButton/PortfolioButton';
import PortfolioSvgSelector from 'assets/images/icons/portfolio/PortfolioSvgSelector';

import { motion } from 'framer-motion';

import portfolioCarouselProps from 'propTypes/PortfolioCarousel/portfolioCarouselProps';
import getPreviewSrc from 'helpers/getPreviewSrc';
import ProjectVideo from 'components/elements/ProjectVideo/ProjectVideo';
import { useSelector } from 'react-redux';
import GlobalSvgSelector from 'assets/images/icons/global/GlovalSvgSelector';
import ProjectModal from 'components/elements/ProjectModal/ProjectModal';
import { useSwipeable } from 'react-swipeable';
import { mobileWidth } from 'configs/config';
import styles from './PortfolioCarousel.module.scss';

function PortfolioCarousel({ projects }) {
  const [position, setPosition] = React.useState(0);
  const [modalVideoLink, setModalVideoLink] = React.useState(null);
  const [modalProjectAdminType, setModalProjectAdminType] = React.useState(null);

  const isAuth = useSelector((state) => state.adminReducer.admin.isAuth);

  const carouselElement = React.useRef(null);
  const carouselElementWidth = window.innerWidth > parseInt(mobileWidth, 10) ? 800 + 40 : 340 + 10; // element width + margin right
  const projectTransition = { duration: 0.5 };
  const carouselStyle = { transform: `translateX(${-carouselElementWidth * position}px)` };

  React.useEffect(() => {
    if (projects.length > 0) setPosition(Math.ceil((projects.length / 2) - 1));
  }, [projects]);

  function adminButtonHandle(event, type, idx = -1) {
    if (position === idx) {
      event.stopPropagation();
      setModalProjectAdminType(type);
      return 1;
    }
    if (type === 'add') {
      setModalProjectAdminType(type);
      return 1;
    }

    return 0;
  }

  const swipeLeft = React.useCallback(() => {
    if (position < projects.length - 1) {
      setPosition(position + 1);
      const elemCurrentTranslateX = Number(carouselElement.current.style.transform.split(' ')[0].match(/([+-]?\d+)/)[0]);

      carouselElement.current.style.transform = `translateX(${Number(elemCurrentTranslateX) - (carouselElementWidth)}px)`;
    } else if (position === projects.length - 1) {
      setPosition(0);
      carouselElement.current.style.transform = 'translateX(0px)';
    }
  }, [position, setPosition, carouselElement, carouselElementWidth, projects]);

  const swipeRight = React.useCallback(() => {
    if (position > 0) {
      setPosition(position - 1);
      const elemCurrentTranslateX = Number(carouselElement.current.style.transform.split(' ')[0].match(/([+-]?\d+)/)[0]);

      carouselElement.current.style.transform = `translateX(${Number(elemCurrentTranslateX) + (carouselElementWidth)}px)`;
    } else if (position === 0) {
      setPosition(projects.length - 1);
      carouselElement.current.style.transform = `translateX(${-carouselElementWidth * projects.length - 1}px)`;
    }
  }, [position, setPosition, carouselElement, carouselElementWidth]);

  function onClickProject(idx) {
    if (position < idx) swipeLeft();
    if (position > idx) swipeRight();
    if (position === idx) setModalVideoLink(projects[idx].link);
    return 1;
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => swipeLeft(),
    onSwipedRight: () => swipeRight(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <>
      {modalVideoLink && (<ProjectVideo modalVideoLink={modalVideoLink} setModalVideoLink={setModalVideoLink} link={projects.link} />)}
      {modalProjectAdminType && (<ProjectModal modalProjectAdminType={modalProjectAdminType} setModalProjectAdminType={setModalProjectAdminType} type={modalProjectAdminType} projectId={projects[position].project_id} />)}
      <div className={styles.wrapper}>
        <motion.div
          className={styles.carousel}
          style={carouselStyle}
          ref={carouselElement}
          {...handlers}
        >
          {projects.map((project, idx) => (
            <motion.div
              className={styles.project}
              key={`project #${project.project_id}`}
              animate={{
                // scale: position === idx ? 1 : 0.95,
                filter: position === idx ? 'brightness(100%)' : 'brightness(20%)'
              }}
              transition={projectTransition}
              onClick={() => onClickProject(idx)}
            >
              <img className={styles.img} src={getPreviewSrc(project.link)} alt={`project img #${project.project_id}`} loading="lazy" />
              {isAuth && (<GlobalSvgSelector id="delete" className={styles.close} onClick={(event) => adminButtonHandle(event, 'delete', idx)} />)}
              {isAuth && (<GlobalSvgSelector id="edit" className={styles.edit} onClick={(event) => adminButtonHandle(event, 'edit', idx)} />)}
            </motion.div>
          ))}
        </motion.div>
        <div className={styles.buttons}>
          <PortfolioButton customClassName={styles.button} onClick={swipeRight}>
            <PortfolioSvgSelector id="left-arrow" />
          </PortfolioButton>
          <PortfolioButton customClassName={styles.button} onClick={swipeLeft}>
            <PortfolioSvgSelector id="right-arrow" />
          </PortfolioButton>
        </div>
        <div className={styles.lines}>
          {projects.map((project, idx) => (
            <span
              className={styles.line}
              key={`project line #${project.project_id}`}
              style={{
                backgroundColor: position === idx ? '#FFAF00' : '#292929',
                boxShadow: position === idx ? '0 0 10px rgba(236, 156, 159, 1)' : 'none'
              }}
              onClick={() => setPosition(idx)}
            />
          ))}
        </div>
        { isAuth && (
          <button type="button" className={`${styles.button} ${styles['admin-button']}`} onClick={(event) => adminButtonHandle(event, 'add')}>
            Add new project
          </button>
        ) }
      </div>
    </>
  );
}

PortfolioCarousel.propTypes = portfolioCarouselProps;

export default PortfolioCarousel;
