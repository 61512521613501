import React from 'react';

import { motion } from 'framer-motion';
import { Link } from 'react-scroll';

import burgerProps from 'propTypes/Burger/burgerProps';

import GlobalSvgSelector from 'assets/images/icons/global/GlovalSvgSelector';
import styles from './Burger.module.scss';

function Burger({
  isBurgerActive, setIsBurgerActive, sectionsId, overlay, burgerPanelArea
}) {
  function closeBurger() {
    if (isBurgerActive) setIsBurgerActive(false);

    overlay.current.style.opacity = 0;
    overlay.current.style.visibility = 'hidden';

    burgerPanelArea.current.style.visibility = 'hidden';
  }

  const burgerPanelAreaMotion = {
    initial: {
      transform: 'translateX(320px)'
    },
    animate: {
      transform: isBurgerActive ? 'translateX(0px)' : 'translateX(320px)'
    },
    transition: {
      duration: 0.5
    }
  };

  const mobileOffset = [0, -340, -50, 0, 20];

  return (
    <>
      <div className={styles.overlay} ref={overlay} onClick={closeBurger} />
      <div className={styles['burger-panel-area']} ref={burgerPanelArea}>
        <motion.div
          initial={burgerPanelAreaMotion.initial}
          animate={burgerPanelAreaMotion.animate}
          transition={burgerPanelAreaMotion.transition}
          className={styles['burger-panel']}
        >
          <GlobalSvgSelector id="close" className={styles.close} onClick={closeBurger} />
          <nav className={styles['burger-nav']}>
            {sectionsId.map((section, idx) => (
              <Link
                to={section}
                smooth
                duration={1000}
                offset={mobileOffset[idx]}
                key={`burger-${section}`}
                onClick={closeBurger}
                className={styles['nav-element']}
              >{section}
              </Link>
            ))}
          </nav>
        </motion.div>
      </div>
    </>
  );
}

Burger.propTypes = burgerProps;

export default Burger;
