import React from 'react';

import YouTube from 'react-youtube';

import getVideoId from 'helpers/getVideoId';
import projectVideoProps from 'propTypes/ProjectVideo/projectVideoProps';

import styles from './ProjectVideo.module.scss';

function ProjectVideo({ modalVideoLink, setModalVideoLink }) {
  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      setModalVideoLink(null);
    }
  };

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className={styles.overlay} onClick={handleClose}>
      <YouTube
        className={styles.video}
        videoId={getVideoId(modalVideoLink)}
        opts={{
          width: '100%',
          height: '100%',
          playerVars: {
            autoplay: 1,
            controls: 1
          }
        }}
      />
    </div>
  );
}

ProjectVideo.propTypes = projectVideoProps;

export default ProjectVideo;
