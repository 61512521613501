import React from 'react';

import projectModalProps from 'propTypes/ProjectModal/projectModalProps';

import { useMutation } from 'react-query';
import { addProject, deleteProject, editProject } from 'services/admin';
import styles from './ProjectModal.module.scss';

function ProjectModal({
  modalProjectAdminType, setModalProjectAdminType, type, projectId
}) {
  const [inputLink, setInputLink] = React.useState('');

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      setModalProjectAdminType(false);
    }
  };

  const onChange = (event) => {
    setInputLink(event.target.value);
  };

  const mutateHookEdit = useMutation(
    'admin edit project',
    (data) => editProject(data.projectId, data.link),
    {
      onSuccess() {
        window.location.reload();
      },
      onError(error) {
        console.log('Project edit error:', error);
      }
    }
  );
  const mutateHookAdd = useMutation(
    'admin add project',
    (data) => addProject(data.link),
    {
      onSuccess() {
        window.location.reload();
      },
      onError(error) {
        console.log('Project add error:', error);
      }
    }
  );
  const mutateHookDelete = useMutation(
    'admin delete project',
    (data) => deleteProject(data.projectId),
    {
      onSuccess() {
        window.location.reload();
      },
      onError(error) {
        console.log('Project edit error:', error);
      }
    }
  );

  function buttonHandle() {
    const data = { projectId, link: inputLink };

    if (modalProjectAdminType === 'edit') {
      mutateHookEdit.mutate(data);
    } else if (modalProjectAdminType === 'add') {
      mutateHookAdd.mutate(data);
    } else if (modalProjectAdminType === 'delete') {
      mutateHookDelete.mutate(data);
    }
  }

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!modalProjectAdminType) return null;
  return (
    <div className={styles.overlay} onClick={handleClose}>
      <div className={styles.modal}>
        <p className={styles.title}>{`${type} project`}</p>
        <hr />
        { type === 'add' || type === 'edit' ? (
          <input type="text" onChange={onChange} className={styles.input} placeholder="Enter video link" />
        ) : (
          type === 'delete' ? (
            <p className={styles['delete-text']}>Are you sure you want to DELETE the project?</p>
          ) : null) }
        <button type="submit" onClick={buttonHandle} className={styles.button}>
          Confirm
        </button>
      </div>
    </div>
  );
}

ProjectModal.propTypes = projectModalProps;

export default ProjectModal;
