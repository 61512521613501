import React from 'react';

const getCoef = (index, isMobile) => {
  const START_COEF = isMobile ? 0.3 : 0.2;
  const STEP_COEF = isMobile ? 0.1 : 0.05;
  const SWITCH_COEF = 12;

  if (index <= SWITCH_COEF) {
    return START_COEF + index * STEP_COEF;
  }
  return START_COEF + 0.55 + (index - SWITCH_COEF - 1) * 0.03;
};

const useScrollPaths = (scrollYProgress, refParagraphs, setLampActive, isMobile) => {
  const handleScroll = React.useCallback(() => {
    const scrollPercentage = scrollYProgress.current;

    for (let i = 0; i < refParagraphs.length; i++) {
      if (scrollPercentage >= getCoef(i, isMobile) && refParagraphs[i]) {
        if (i === 0) setLampActive(true);
        refParagraphs[i].style.opacity = 1;
      } else {
        if (i === 0) setLampActive(false);
        refParagraphs[i].style.opacity = 0.2;
      }
    }
  }, [scrollYProgress, isMobile]);

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
};

export default useScrollPaths;
