import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { store, persistor } from 'redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import useWebpSupport from 'hooks/useWebpSupport';
import ErrorBoundary from './ErrorBoundary';

import appProvidersProps from './propTypes/AppProviders/appProvidersProps';

import Context from './context';

const queryClient = new QueryClient();

export default function AppProviders({ children }) {
  const isWebpSupported = useWebpSupport();

  const contextValue = React.useMemo(() => ({ webp: isWebpSupported }), [isWebpSupported]);

  return (
    <QueryClientProvider client={queryClient}>
      <Context.Provider value={contextValue}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </PersistGate>
        </Provider>
      </Context.Provider>
    </QueryClientProvider>
  );
}

AppProviders.propTypes = appProvidersProps;
