import React from 'react';

import HeaderSvgSelector from 'assets/images/icons/header/HeaderSvgSelector';

import styles from './Dropdown.module.scss';

function Dropdown() {
  return (
    <div className={styles.dropdown}>
      <div className={styles.option}>
        EN
      </div>
      <HeaderSvgSelector id="dropdown-arrow" fill="#1E1F2D" />
    </div>
  );
}

export default Dropdown;
