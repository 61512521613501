import React from 'react';

import selectorProps from 'propTypes/Selector/selectorProps';

import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Language } from './language.svg';
import { ReactComponent as DropdownArrow } from './dropdown-arrow.svg';

function HeaderSvgSelector({ id, ...props }) {
  switch (id) {
  case 'logo':
    return (<Logo {...props} />);
  case 'language':
    return (<Language {...props} />);
  case 'dropdown-arrow':
    return (<DropdownArrow {...props} />);

  default:
    return null;
  }
}

HeaderSvgSelector.propTypes = selectorProps;

export default React.memo(HeaderSvgSelector);
