import React from 'react';

import HeroSvgSelector from 'assets/images/icons/hero/HeroSvgSelector';

import heroButtonProps from 'propTypes/HeroButton/heroButtonProps';
import heroButtonDefaultProps from 'propTypes/HeroButton/heroButtonDefaultProps';

import styles from './HeroButton.module.scss';

function HeroButton({
  onClick, type, customClassName
}) {
  const svgSelectorId = type === 'left' ? 'left-arrow' : 'right-arrow';
  const arrowStyles = customClassName ? `${customClassName} ${styles.arrow}` : styles.arrow;

  return (
    <div
      className={arrowStyles}
      onClick={onClick}
    >
      <div className={styles['arrow-inner']}>
        <HeroSvgSelector id={svgSelectorId} />
      </div>
    </div>
  );
}

HeroButton.propTypes = heroButtonProps;
HeroButton.defaultPropTypes = heroButtonDefaultProps;

export default HeroButton;
