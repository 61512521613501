import getVideoId from 'helpers/getVideoId';

function getPreviewSrc(videoUrl) {
  const videoId = getVideoId(videoUrl);
  const videoPreviewSrc = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

  return videoPreviewSrc;
}

export default getPreviewSrc;
