import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { PURGE } from 'redux-persist';
import config from '../../configs/config';

const initialState = {
  admin: {
    adminId: '',
    isAuth: false
  }
};

export const checkAuth = createAsyncThunk(
  'admin/checkAuth',
  async () => {
    try {
      const response = await axios.get(`${config.url}/api/admin/refresh`, { withCredentials: true });
      const { accessToken, admin } = response.data;
      localStorage.setItem('token', accessToken);

      return admin;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
);

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAuth(state, action) {
      state.admin.isAuth = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(checkAuth.pending, (state) => {
      // ����� ����� �������� ����� ��� ������ �������
      state.loading = true;
    });
    builder.addCase(checkAuth.fulfilled, (state) => {
      // ����� ����� �������� ����� ��� �������� ���������� �������
      state.loading = false;
      state.admin.isAuth = true;
    });
    builder.addCase(checkAuth.rejected, (state, action) => {
      // ����� ����� �������� ����� ��� ������ �������
      state.loading = false;
      state.error = action.error.message;
    });
  }
});

export const {
  setAuth
} = adminSlice.actions;

export default adminSlice.reducer;
