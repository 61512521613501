const PropTypes = require('prop-types');

module.exports = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      project_id: PropTypes.number,
      link: PropTypes.string
    })
  )
};
