import React from 'react';

import { useSwipeable } from 'react-swipeable';
import { AnimatePresence, motion, useScroll } from 'framer-motion';

import getRoadmapImages from 'helpers/getRoadmapImages';
import useScrollParagraphs from 'hooks/useScrollParagraphs';
import useImages from 'hooks/useImages';

import Title from 'components/UI/Title/Title';

import Context from 'context';

import lampOn from 'assets/images/roadmap/png/lamps/Lamp on.png';
import lampOff from 'assets/images/roadmap/png/lamps/Lamp_off.png';
import styles from './RoadmapMobile.module.scss';

/* eslint-disable no-return-assign */

function RoadmapMobile() {
  const [position, setPosition] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [lampActive, setLampActive] = React.useState(false);
  const refParagraphs = React.useRef([]);
  const refParent = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: refParent,
    offset: ['start end', 'end end']
  });

  const images = getRoadmapImages();
  const { webp } = React.useContext(Context);
  const { imageSrcs, activateImage } = useImages(images, webp);
  const imgStyleOpacity = { opacity: activateImage ? 1 : 0 };

  const roadmapStyleBg = { backgroundImage: `url(${imageSrcs.branchBg})` };

  const elements = [
    {
      title: 'IDEA',
      jsx:
  <>
    <div className={styles.stone} id={styles.stone4out}>
      <img src={imageSrcs.stone4out} alt="stone4out" style={imgStyleOpacity} loading="lazy" />
    </div>
    <div className={styles.stone} id={styles.stone13out}>
      <img src={imageSrcs.stone13out} alt="stone13out" style={imgStyleOpacity} loading="lazy" />
    </div>
    <div className={styles.stone} id={styles.stone1}>
      <img src={imageSrcs.stone1} alt="stone1" style={imgStyleOpacity} loading="lazy" />
      <img className={styles.lamp} src={`${lampActive ? lampOn : lampOff}`} alt="roadmap lamp" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 0 ? (el) => (refParagraphs.current[0] = el) : null}
      >IDEA
      </p>
    </div>
    <div className={styles.stone} id={styles.stone2}>
      <img src={imageSrcs.stone2} alt="stone2" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.orange}`}
        ref={position === 0 ? (el) => (refParagraphs.current[1] = el) : null}
      >PRE PRODUCTION
      </p>
    </div>
    <div className={styles.stone} id={styles.stone3}>
      <img src={imageSrcs.stone3} alt="stone3" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 0 ? (el) => (refParagraphs.current[2] = el) : null}
      >STORY
      </p>
    </div>
    <div className={styles.stone} id={styles.stone4}>
      <img src={imageSrcs.stone4} alt="stone4" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 0 ? (el) => (refParagraphs.current[3] = el) : null}
      >STORY<br />BOARD
      </p>
    </div>
    <div className={styles.stone} id={styles.stone5}>
      <img src={imageSrcs.stone5} alt="stone5" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 0 ? (el) => (refParagraphs.current[4] = el) : null}
      >ANIMATIC
      </p>
    </div>
    <div className={styles.stone} id={styles.stone6}>
      <img src={imageSrcs.stone6} alt="stone6" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 0 ? (el) => (refParagraphs.current[5] = el) : null}
      >CONCEPT<br />DESIGN
      </p>
    </div>
  </>
    },
    {
      title: 'PRODUCTION',
      jsx:
  <>
    <div className={styles.stone} id={styles.stone3out}>
      <img src={imageSrcs.stone3out} alt="stone3out" style={imgStyleOpacity} loading="lazy" />
    </div>
    <div className={styles.stone} id={styles.stone5out}>
      <img src={imageSrcs.stone5out} alt="stone5out" style={imgStyleOpacity} loading="lazy" />
    </div>
    <div className={styles.stone} id={styles.stone7}>
      <img src={imageSrcs.stone7} alt="stone7" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.orange}`}
        ref={position === 1 ? (el) => (refParagraphs.current[0] = el) : null}
      >PRODUCTION
      </p>
    </div>
    <div className={styles.stone} id={styles.stone8}>
      <img src={imageSrcs.stone8} alt="stone8" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 1 ? (el) => (refParagraphs.current[1] = el) : null}
      >LAYOUT
      </p>
    </div>
    <div className={styles.stone} id={styles.stone9}>
      <img src={imageSrcs.stone9} alt="stone9" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 1 ? (el) => (refParagraphs.current[2] = el) : null}
      >VFX
      </p>
    </div>
    <div className={styles.stone} id={styles.stone10}>
      <img src={imageSrcs.stone10} alt="stone10" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 1 ? (el) => (refParagraphs.current[3] = el) : null}
      >MODELING
      </p>
    </div>
    <div className={styles.stone} id={styles.stone11}>
      <img src={imageSrcs.stone11} alt="stone11" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 1 ? (el) => (refParagraphs.current[4] = el) : null}
      >LIGHTING
      </p>
    </div>
    <div className={styles.stone} id={styles.stone12}>
      <img src={imageSrcs.stone12} alt="stone12" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 1 ? (el) => (refParagraphs.current[5] = el) : null}
      >TEXTURING
      </p>
    </div>
    <div className={styles.stone} id={styles.stone13}>
      <img src={imageSrcs.stone13} alt="stone13" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 1 ? (el) => (refParagraphs.current[6] = el) : null}
      >ANIMATION
      </p>
    </div>
    <div className={styles.stone} id={styles.stone14}>
      <img src={imageSrcs.stone14} alt="stone14" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 1 ? (el) => (refParagraphs.current[7] = el) : null}
      >RIGGING &<br />SETUP
      </p>
    </div>
  </>
    },
    {
      title: 'FINAL',
      jsx:
  <>
    <div className={styles.stone} id={styles.stone6out}>
      <img src={imageSrcs.stone6out} alt="stone6out" style={imgStyleOpacity} loading="lazy" />
    </div>
    <div className={styles.stone} id={styles.stone10out}>
      <img src={imageSrcs.stone10out} alt="stone10out" style={imgStyleOpacity} loading="lazy" />
    </div>
    <div className={styles.stone} id={styles.stone16}>
      <img src={imageSrcs.stone16} alt="stone16" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.orange}`}
        ref={position === 2 ? (el) => (refParagraphs.current[0] = el) : null}
      >POST PRODUCTION
      </p>
    </div>
    <div className={styles.stone} id={styles.stone17}>
      <img src={imageSrcs.stone17} alt="stone17" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 2 ? (el) => (refParagraphs.current[1] = el) : null}
      >3D VFX<br />MOTION
      </p>
    </div>
    <div className={styles.stone} id={styles.stone18}>
      <img src={imageSrcs.stone18} alt="stone18" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 2 ? (el) => (refParagraphs.current[2] = el) : null}
      >COMPOSITING
      </p>
    </div>
    <div className={styles.stone} id={styles.stone19}>
      <img src={imageSrcs.stone19} alt="stone19" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 2 ? (el) => (refParagraphs.current[3] = el) : null}
      >COLOR<br />CORRECTION
      </p>
    </div>
    <div className={styles.stone} id={styles.stone20}>
      <img src={imageSrcs.stone20} alt="stone20" style={imgStyleOpacity} loading="lazy" />
      <p
        className={`${styles.text} ${styles.white}`}
        ref={position === 2 ? (el) => (refParagraphs.current[4] = el) : null}
      >FINAL OUTPUT
      </p>
    </div>
  </>
    }
  ];

  function disableButton() {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 1250);
  }

  function setPositionHandle(idx) {
    if (isDisabled) return 0;
    setPosition(idx);
    disableButton();
    setLampActive(false);

    return 1;
  }

  function swipeLeft() {
    if (position < elements.length - 1) {
      setPositionHandle(position + 1);
    } else if (position === elements.length - 1) {
      setPositionHandle(0);
    }
  }

  function swipeRight() {
    if (position > 0) {
      setPositionHandle(position - 1);
    } else if (position === 0) {
      setPositionHandle(elements.length - 1);
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => swipeLeft(),
    onSwipedRight: () => swipeRight(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const roadmapMotion = {
    animate: { x: 0, opacity: 1, transition: { delay: 0.75 } },
    initial: { x: -70, opacity: 0 },
    exit: { x: -70, opacity: 0 },
    transition: { duration: 0.25 }
  };

  useScrollParagraphs(scrollYProgress, refParagraphs.current, setLampActive, true);

  return (
    <div className={styles.roadmap} style={roadmapStyleBg}>
      <div className={styles['title-wrapper']}>
        <p className={styles.desc}>our pipeline</p>
        <Title>RoadMap</Title>
      </div>
      <div className={styles.glow} />
      <div className={styles['stones-wrapper-mobile']} ref={refParent}>
        <div className={styles.top}>
          <div className={styles.dots}>
            {elements.map((element, idx) => (
              <div
                key={element.title}
                className={styles.dot}
                style={{ backgroundColor: position === idx ? '#FEA501' : '#292929' }}
              />
            ))}
          </div>
          <p className={styles.name}>{elements[position].title}</p>
          <p className={styles.counter}>{`${position + 1}/${elements.length}`}</p>
        </div>
        <div className={styles.bottom} ref={refParent} {...handlers}>
          <AnimatePresence>
            <motion.div
              className={styles['bottom-wrapper']}
              key={`roadmap-page-${position}`}
              animate={roadmapMotion.animate}
              initial={roadmapMotion.initial}
              exit={roadmapMotion.exit}
              transition={roadmapMotion.transition}
            >
              {elements[position].jsx}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default RoadmapMobile;
