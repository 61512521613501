import React from 'react';

import HeaderSvgSelector from 'assets/images/icons/header/HeaderSvgSelector';
import FooterSvgSelector from 'assets/images/icons/footer/FooterSvgSelector';
import styles from './Footer.module.scss';

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles['logo-section']}>
        <HeaderSvgSelector id="logo" />
        <p className={styles.rights}>NoctuaDomain Production LLC-FZ.</p>
        <p className={styles.rights}>All rights reserved.</p>
        <p className={styles.rights}>2023.</p>
      </div>
      <div className={styles['links-wrapper']}>
        <div className={styles['links-block']}>
          <div className={styles.title}>Socials:</div>
          <div className={styles['elements-wrapper-socials']}>
            <a className={styles.element} href="https://instagram.com/noctuadomain?igshid=MzNlNGNkZWQ4Mg==" target="_blank" rel="noreferrer">
              <FooterSvgSelector id="instagram" />
              <p className={styles.name}>Instagram</p>
            </a>
            <a className={styles.element} href="https://www.youtube.com/@NoctuaDomainProduction" target="_blank" rel="noreferrer">
              <FooterSvgSelector id="youtube" />
              <p className={styles.name}>Youtube</p>
            </a>
          </div>
        </div>
        <div className={styles['links-block']}>
          <div className={styles.title}>Contact Us:</div>
          <div className={styles['elements-wrapper-contact']}>
            <a href="mailto:info@noctuadomain.com" className={styles.element}>
              <FooterSvgSelector id="mail" />
              <p className={styles.name}>info@noctuadomain.com</p>
            </a>
            <a href="tel:+971563959117" className={styles.element}>
              <FooterSvgSelector id="phone" />
              <p className={styles.name}>+971 56 395 9117</p>
            </a>
            <a href="tel:+971505637748" className={styles.element}>
              <FooterSvgSelector id="phone" />
              <p className={styles.name}>+971 50 563 7748</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
