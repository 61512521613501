import React from 'react';

import useImages from 'hooks/useImages';

import Context from 'context';

import {
  motion, AnimatePresence, useScroll, useTransform
} from 'framer-motion';
import GlobalSvgSelector from 'assets/images/icons/global/GlovalSvgSelector';
import HeroButton from 'components/UI/HeroButton/HeroButton';
import styles from './Hero.module.scss';

function Hero() {
  const images = {
    owl: {
      webp: import('assets/images/hero/webp/owl.webp'),
      png: import('assets/images/hero/png/owl.png')
    },
    bgLines: {
      webp: import('assets/images/hero/webp/bg-lines.webp'),
      png: import('assets/images/hero/png/bg-lines.png')
    },
    bgCircles: {
      webp: import('assets/images/hero/webp/bg-circles.webp'),
      png: import('assets/images/hero/png/bg-circles.png')
    }
  };
  const webp = React.useContext(Context);
  const { imageSrcs, activateImage } = useImages(images, webp);
  const [position, setPosition] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const imgStyleOpacity = { opacity: activateImage ? 1 : 0 };

  const elements = [
    {
      title: 'Animation',
      text:
  <>
    <p className={styles.text}>
      Bring your projects to life with 3D or 2D animation instead of thousand words
    </p>
    <p className={styles.text}>
      (Even a small video can leave deep emotions, what we will create will be unforgettable)
    </p>
  </>
    },
    {
      title: '3D mapping',
      text:
  <p className={styles.text}>
    What is 3D mapping? It is an amazing way to transform any type of surface into canvas for spectacular animation show
  </p>
    },
    {
      title: 'Post production',
      text:
  <p className={styles.text}>
    Do you have video that you want to enhance with additional graphic elements, motion graphics, VFX? We will help you to achieve that
  </p>
    },
    {
      title: 'Performance',
      text:
  <p className={styles.text}>
    Any show can be made unique and memorable by adding new technologies and animation content
  </p>
    }
  ];

  const delayBeforeAnimation = 0.2;
  const transitionAnimation = 0.4;

  const leftMotion = {
    animate: { x: 0, opacity: 1, transition: { delay: delayBeforeAnimation } },
    initial: { x: 500, opacity: 0 },
    exit: { x: 500, opacity: 0 },
    transition: { duration: transitionAnimation }
  };

  function disableButton() {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, ((delayBeforeAnimation + transitionAnimation) * 1000) + 300);
  }

  function nextPage() {
    if (isDisabled) return 0;
    if (position < elements.length - 1) {
      setPosition(position + 1);
      disableButton();
    } else if (position === elements.length - 1) {
      setPosition(0);
      disableButton();
    }

    return 1;
  }

  function prevPage() {
    if (isDisabled) return 0;
    if (position > 0) {
      setPosition(position - 1);
      disableButton();
    } else if (position === 0) {
      setPosition(elements.length - 1);
      disableButton();
    }

    return 1;
  }

  const { scrollY } = useScroll({
    offset: ['start end', 'end end']
  });

  const windowWidth = window.innerWidth;

  const xTextBlockInputRange = [0, 700];
  const opacityTextBlockInputRange = windowWidth > 1440 ? [0, 700] : windowWidth > 900 ? [0, 500] : [0, 300];

  const xTextBlock = useTransform(scrollY, xTextBlockInputRange, [0, 400]);
  const opacityTextBlock = useTransform(scrollY, opacityTextBlockInputRange, [1, 0]);

  return (
    <div className={styles.hero} id="home">
      <img className={styles.circles} src={imageSrcs.bgCircles} alt="background circles" style={imgStyleOpacity} />
      <img className={styles.lines} src={imageSrcs.bgLines} alt="background lines" style={imgStyleOpacity} />
      <div className={styles.glow} />
      <div className={styles.left}>
        <div className={styles['slider-block']}>
          <div className={styles.dots}>
            {elements.map((element, idx) => (
              <div
                key={element.title}
                className={styles.dot}
                style={{
                  backgroundColor: position === idx ? '#FEA501' : '#292929'
                }}
              />
            ))}
          </div>
        </div>
        <div className={styles['text-block']}>
          <span className={styles['left-border']} />
          <span className={styles['top-border']} />
          <span className={styles['right-border']} />
          <motion.div
            className={styles['text-block-inner']}
            style={{
              x: xTextBlock,
              opacity: opacityTextBlock
            }}
          >
            <AnimatePresence mode="wait">
              <motion.div
                animate={leftMotion.animate}
                initial={leftMotion.initial}
                exit={leftMotion.exit}
                transition={leftMotion.transition}
                key={`text-block-${position}`}
              >
                <div className={styles.title}>{elements[position].title}</div>
                <div className={styles.desc}>{elements[position].text}</div>
              </motion.div>
            </AnimatePresence>
            <p className={styles['static-text']}>
              See how our experts CG artists can create an animation where brilliant ideas come to life.
            </p>
            <div className={styles['arrows-block']}>
              <HeroButton onClick={prevPage} type="left" />
              <HeroButton onClick={nextPage} type="right" />
            </div>
          </motion.div>
        </div>
      </div>
      <div className={styles.socials}>
        <a className={styles.element} href="https://www.whatsapp.com/" target="_blank" rel="noreferrer">
          <GlobalSvgSelector id="whatsapp" />
        </a>
        <a className={styles.element} href="https://web.telegram.org/k/" target="_blank" rel="noreferrer">
          <GlobalSvgSelector id="telegram" />
        </a>
      </div>
      <div className={styles.right}>
        <img className={styles.image} src={imageSrcs.owl} alt="owl" style={imgStyleOpacity} />
      </div>
    </div>
  );
}

export default Hero;
