import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import Title from 'components/UI/Title/Title';

import Context from 'context';

import useImages from 'hooks/useImages';

import ServicesSvgSelector from 'assets/images/icons/services/ServicesSvgSelector';
import ServicesMainMobile from 'components/elements/ServicesMainMobile/ServicesMainMobile';
import styles from './Services.module.scss';

function Services() {
  const images = {
    bg3dMapping: {
      webp: import('assets/images/services/webp/bg-3d-mapping.webp'),
      png: import('assets/images/services/png/bg-3d-mapping.png')
    },
    bgCharactersAndEnvironment: {
      webp: import('assets/images/services/webp/bg-character-and-environment.webp'),
      png: import('assets/images/services/png/bg-character-and-environment.png')
    },
    bg3d2dAnimations: {
      webp: import('assets/images/services/webp/bg-3d-2d-animations.webp'),
      png: import('assets/images/services/png/bg-3d-2d-animations.png')
    },
    bgVfx: {
      webp: import('assets/images/services/webp/bg-vfx.webp'),
      png: import('assets/images/services/png/bg-vfx.png')
    },
    bgMotionGraphics: {
      webp: import('assets/images/services/webp/bg-motion-graphics.webp'),
      png: import('assets/images/services/png/bg-motion-graphics.png')
    },
    bgVideoShooting: {
      webp: import('assets/images/services/webp/bg-video-shooting.webp'),
      png: import('assets/images/services/png/bg-video-shooting.png')
    },
    bgArVr: {
      webp: import('assets/images/services/webp/bg-ar-vr.webp'),
      png: import('assets/images/services/png/bg-ar-vr.png')
    },
    bgPerformance: {
      webp: import('assets/images/services/webp/bg-performance.webp'),
      png: import('assets/images/services/png/bg-performance.png')
    },
    backgroundImageLeft: {
      webp: import('assets/images/services/webp/bg-left.webp'),
      png: import('assets/images/services/png/bg-left.png')
    },
    backgroundImageOwl: {
      webp: import('assets/images/services/webp/owl-detail.webp'),
      png: import('assets/images/services/png/owl-detail.png')
    },
    bgLines: {
      webp: import('assets/images/services/webp/lines.webp'),
      png: import('assets/images/services/png/lines.png')
    }
  };
  const { webp } = React.useContext(Context);
  const { imageSrcs, activateImage } = useImages(images, webp);
  const [position, setPosition] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const navElements = [
    {
      title: '3D mapping',
      iconId: '3d-mapping',
      img: imageSrcs.bg3dMapping,
      text:
  <>
    <p className="services-text">
      <span className="bold">What is 3D mapping art?</span> It is large scale, amazing animation show on any type of surface (building, sculpture, water, gas, car)
      And we know how is everything works and how to create a miracle
    </p>
    <p className="services-text">
      How you can use this miracle?
    </p>
    <br />
    <div className="services-text">
      <ul className="list">
        <li>Product launch</li>
        <li>Advertisement</li>
        <li>Announce of festival, discount, event etc</li>
        <li>Big holidays</li>
      </ul>
    </div>
  </>
    },
    {
      title: 'Characters and environments',
      iconId: 'characters-and-environment',
      img: imageSrcs.bgCharactersAndEnvironment,
      text:
  <>
    <p className="services-text">
      Character and environment design is hard part of animation creating where brilliant ideas come to life.
    </p>
    <p className="services-text">
      <span className="bold">Every story needs unique heroes and appropriate surrounding.</span>
    </p>
    <br />
    <div className="services-text">
      <ul className="list">
        <li>What can we do?</li>
        <li>Nature environment elements (trees, flowers, rocks)</li>
        <li>Simulation of water, fire, smoke etc</li>
        <li>Accessories & Weapons</li>
        <li>Vehicles (cars, buses, planes etc)</li>
        <li>Human and fairy tale characters</li>
        <li>Fully textured and rigged characters</li>
        <li>Terrain (mountains, islands)</li>
      </ul>
    </div>
  </>
    },
    {
      title: '3D&2D animations',
      iconId: '3d-2d-animations',
      img: imageSrcs.bg3d2dAnimations,
      text:
  <>
    <p className="services-text">
      The best way to explain or wonder someone is <span className="bold">showing</span> Visualization of information gives six times better effect than written or verbal information.
    </p>
    <p className="services-text">
      Even a small 3d animation video can leave unforgettable emotions, especially if <span className="bold">we</span> will create it.
    </p>
  </>
    },
    {
      title: 'VFX',
      iconId: 'vfx',
      img: imageSrcs.bgVfx,
      text:
  <p className="services-text">
    Visual effects stand for visual effects into your video. Visual effects create or manipulate film imagery to add impressive content, characters, actions or environment to visual storytelling.
    Used for movies and TV shows, commercials, music videos, teasers, product launches etc.
  </p>
    },
    {
      title: 'Motion graphics',
      iconId: 'motion-graphics',
      img: imageSrcs.bgMotionGraphics,
      text:
  <>
    <p className="services-text">
      The most popular type of graphic for advertisement, explain videos, study videos, popular blogs, social media, opening of TV shows.
    </p>
    <p className="services-text gray">
      Motion graphics are a great way to propel your brand forward. They have several benefits which not only grow your brand but also move the books into the black due to an increase in conversations.
    </p>
  </>
    },
    {
      title: 'Video shooting/production',
      iconId: 'video-shooting',
      img: imageSrcs.bgVideoShooting,
      text:
  <>
    <p className="services-text">
      Looking for social media video production in UAE? With our video coverage services you can just share your wishes and enjoy the process. We will care about everything.
    </p>
    <p className="services-text">
      Our video shooting services include everything from idea and scenario to editing and VFX. We specialize in professional corporate video production, commercial video marketing, digital video creation 4K/6K , social media video production, trailers.
    </p>
  </>
    },
    {
      title: 'AR/VR',
      iconId: 'ar-vr',
      img: imageSrcs.bgArVr,
      text:
  <>
    <p className="services-text gray">
      Augmented reality and virtual reality (commonly abbreviated as AR and VR respectively) are reality technologies that either enhance or replace a real-life environment with a simulated one.
    </p>
    <p className="services-text bold">
      Programming + animation or design = AR/VR
      We provide full service of it.
    </p>
    <p className="services-text gray">
      You can successfully use it for <span className="bold">advertisement, exhibitions, education, events, performance</span> etc.
    </p>
  </>
    },
    {
      title: 'Performance',
      iconId: 'performance',
      img: imageSrcs.bgPerformance,
      text:
  <>
    <p className="services-text">
      Each show is a unique work of art, and that&apos;s exactly the attitude we have towards the performance.
    </p>
    <p className="services-text">
      If you want to surprise and captivate the attention of your audience, we offer creative solutions: interactive dance, 3D mapping instead of conventional decorations, laser show and much more.
    </p>
  </>
    }
  ];

  const imgStyleOpacity = { opacity: activateImage ? 1 : 0 };
  const leftStyleBg = { backgroundImage: `url(${imageSrcs.backgroundImageLeft})` };
  const rightMotion = {
    animate: { x: 0, opacity: 1, transition: { delay: 0.5 } },
    initial: { x: -70, opacity: 0 },
    exit: { x: -70, opacity: 0 },
    transition: { duration: 0.25 }
  };

  const rightImgMotion = {
    animate: { opacity: 1, transition: { delay: 0, duration: 1.5 } },
    initial: { opacity: 0 },
    exit: { opacity: 0 },
    transition: { duration: 0.25 }
  };

  function elementNameAnimate(idx) {
    return {
      color: position === idx ? '#FFAA00' : '#676C9E',
      textShadow: position === idx ? '0px 0px 20px rgb(255, 170, 0), 0 0 30px rgb(255, 170, 0)' : '0px 0px 0px rgba(255,255,255,0)'
    };
  }

  function disableButton() {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 750);
  }

  function setPositionHandle(idx) {
    if (isDisabled) return 0;
    disableButton();
    setPosition(idx);

    return 1;
  }

  return (
    <div className={styles.services} id="services">
      <img className={styles['bg-owl']} src={imageSrcs.backgroundImageOwl} alt="background owl" loading="lazy" style={imgStyleOpacity} />
      <img className={styles.lines} src={imageSrcs.bgLines} alt="background lines" loading="lazy" style={imgStyleOpacity} />
      <div className={styles['title-wrapper']}>
        <Title>Our Services</Title>
        <p className={styles.desc}>experience the miracle of animation</p>
      </div>
      <div className={styles.main}>
        <div className={styles.left} style={leftStyleBg}>
          <div className={styles.nav}>
            {navElements.map((element, idx) => (
              <div className={styles.element} key={element.iconId} onClick={() => setPositionHandle(idx)}>
                <ServicesSvgSelector id={element.iconId} />
                <motion.p
                  className={styles.name}
                  animate={elementNameAnimate(idx)}
                  transition={{ duration: 0.1 }}
                  whileHover={{
                    color: position !== idx ? '#FFFFFF' : '#FFAA00'
                  }}
                >{element.title}
                </motion.p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.right}>
          <AnimatePresence>
            <motion.div
              key={`image-${position}`}
              initial={rightImgMotion.initial}
              animate={rightImgMotion.animate}
              exit={rightImgMotion.exit}
              transition={rightImgMotion.transition}
            >
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={navElements[position].img} className={styles['right-image']} />
            </motion.div>
            <motion.p
              key={`title-${position}`}
              className={styles.title}
              animate={rightMotion.animate}
              initial={rightMotion.initial}
              exit={rightMotion.exit}
              transition={rightMotion.transition}
            >{navElements[position].title}
            </motion.p>
            <motion.div
              key={`text-${position}`}
              className={styles['text-wrapper']}
              animate={rightMotion.animate}
              initial={rightMotion.initial}
              exit={rightMotion.exit}
              transition={rightMotion.transition}
            >{navElements[position].text}
            </motion.div>
          </AnimatePresence>
          <div className={styles.shadow} />
        </div>
      </div>
      <ServicesMainMobile customClassName={styles['main-mobile']} navElements={navElements} position={position} rightImgMotion={rightImgMotion} rightMotion={rightMotion} setPositionHandle={setPositionHandle} />
    </div>
  );
}

export default Services;
