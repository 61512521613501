import React from 'react';

import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import schema from 'helpers/validation/contact-form';
import FormButton from 'components/UI/FormButton/FormButton';
import FormikField from 'components/UI/FormikField/FormikField';
import { sendMail } from 'services/mails';
import Context from 'context';
import useImages from 'hooks/useImages';
import styles from './ContactForm.module.scss';

function ContactForm() {
  const images = {
    whatsapp: {
      webp: import('assets/images/global/webp/whatsapp.webp'),
      png: import('assets/images/global/png/whatsapp.png')
    },
    telegram: {
      webp: import('assets/images/global/webp/telegram.webp'),
      png: import('assets/images/global/png/telegram.png')
    }
  };

  const webp = React.useContext(Context);
  const { imageSrcs, activateImage } = useImages(images, webp);

  const imgStyleOpacity = { opacity: activateImage ? 1 : 0 };

  const [isContactSended, setIsContactSended] = React.useState(false);

  const TG_ID = '+971505637748';
  const PHONE_NUMBER = '971505637748';

  const telegramHref = `https://t.me/${TG_ID}`;
  const whatsAppHref = `https://wa.me/${PHONE_NUMBER}`;

  const mutateHook = useMutation(
    'send mail',
    (data) => sendMail(data),
    {
      onSuccess() {
        setIsContactSended(true);
      },
      onError(error) {
        console.log(error);
      }
    }
  );

  const onFormSubmit = (data) => {
    mutateHook.mutate(data);
  };

  return (
    <Formik
      initialValues={{
        fullName: '',
        email: '',
        text: ''
      }}
      onSubmit={onFormSubmit}
      validationSchema={schema}
    >
      {({
        handleSubmit
      }) => (
        <Form onSubmit={handleSubmit} className={styles.form}>
          {!isContactSended && (
            <>
              <div className={styles['double-field-wrapper']}>
                <FormikField name="fullName" type="text" placeholder="Full Name" title="Full Name" />
                <FormikField name="email" type="text" placeholder="hello@noctua.com" title="Email" />
              </div>
              <FormikField name="text" type="text" placeholder="Describe your problem" title="How can we help you?" textarea />
              <FormButton customClassName={styles.button}>Send Message</FormButton>
              <span className={styles.line} />
              <div className={styles.socials}>
                <a className={styles['social-element']} href={whatsAppHref} target="_blank" rel="noreferrer">
                  <img src={imageSrcs.whatsapp} alt="whatsapp icon" style={imgStyleOpacity} />
                </a>
                <a className={styles['social-element']} href={telegramHref} target="_blank" rel="noreferrer">
                  <img src={imageSrcs.telegram} alt="telegram icon" style={imgStyleOpacity} />
                </a>
              </div>
            </>
          )}
          {isContactSended && (
            <p className={styles.thanks}>Thank you for contacting us!</p>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ContactForm;
