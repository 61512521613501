const PropTypes = require('prop-types');

const navElementShape = PropTypes.shape({
  title: PropTypes.string,
  iconId: PropTypes.string,
  img: PropTypes.string,
  text: PropTypes.node
});

module.exports = {
  customClassName: PropTypes.string,
  navElements: PropTypes.arrayOf(navElementShape).isRequired,
  position: PropTypes.number.isRequired,
  rightStyleBg: PropTypes.object.isRequired,
  rightMotion: PropTypes.object.isRequired,
  setPositionHandle: PropTypes.func.isRequired
};
