function getRoadmapImages() {
  const images = {
    stone1: {
      webp: import('assets/images/roadmap/webp/in/Stone 1.webp'),
      png: import('assets/images/roadmap/png/in/Stone 1.png')
    },
    stone2: {
      webp: import('assets/images/roadmap/webp/in/Stone 2.webp'),
      png: import('assets/images/roadmap/png/in/Stone 2.png')
    },
    stone3: {
      webp: import('assets/images/roadmap/webp/in/Stone 3.webp'),
      png: import('assets/images/roadmap/png/in/Stone 3.png')
    },
    stone4: {
      webp: import('assets/images/roadmap/webp/in/Stone 4.webp'),
      png: import('assets/images/roadmap/png/in/Stone 4.png')
    },
    stone5: {
      webp: import('assets/images/roadmap/webp/in/Stone 5.webp'),
      png: import('assets/images/roadmap/png/in/Stone 5.png')
    },
    stone6: {
      webp: import('assets/images/roadmap/webp/in/Stone 6.webp'),
      png: import('assets/images/roadmap/png/in/Stone 6.png')
    },
    stone7: {
      webp: import('assets/images/roadmap/webp/in/Stone 7.webp'),
      png: import('assets/images/roadmap/png/in/Stone 7.png')
    },
    stone8: {
      webp: import('assets/images/roadmap/webp/in/Stone 8.webp'),
      png: import('assets/images/roadmap/png/in/Stone 8.png')
    },
    stone9: {
      webp: import('assets/images/roadmap/webp/in/Stone 9.webp'),
      png: import('assets/images/roadmap/png/in/Stone 9.png')
    },
    stone10: {
      webp: import('assets/images/roadmap/webp/in/Stone 10.webp'),
      png: import('assets/images/roadmap/png/in/Stone 10.png')
    },
    stone11: {
      webp: import('assets/images/roadmap/webp/in/Stone 11.webp'),
      png: import('assets/images/roadmap/png/in/Stone 11.png')
    },
    stone12: {
      webp: import('assets/images/roadmap/webp/in/Stone 12.webp'),
      png: import('assets/images/roadmap/png/in/Stone 12.png')
    },
    stone13: {
      webp: import('assets/images/roadmap/webp/in/Stone 13.webp'),
      png: import('assets/images/roadmap/png/in/Stone 13.png')
    },
    stone14: {
      webp: import('assets/images/roadmap/webp/in/Stone 14.webp'),
      png: import('assets/images/roadmap/png/in/Stone 14.png')
    },
    stone15: {
      webp: import('assets/images/roadmap/webp/in/Stone 15.webp'),
      png: import('assets/images/roadmap/png/in/Stone 15.png')
    },
    stone16: {
      webp: import('assets/images/roadmap/webp/in/Stone 16.webp'),
      png: import('assets/images/roadmap/png/in/Stone 16.png')
    },
    stone17: {
      webp: import('assets/images/roadmap/webp/in/Stone 17.webp'),
      png: import('assets/images/roadmap/png/in/Stone 17.png')
    },
    stone18: {
      webp: import('assets/images/roadmap/webp/in/Stone 18.webp'),
      png: import('assets/images/roadmap/png/in/Stone 18.png')
    },
    stone19: {
      webp: import('assets/images/roadmap/webp/in/Stone 19.webp'),
      png: import('assets/images/roadmap/png/in/Stone 19.png')
    },
    stone20: {
      webp: import('assets/images/roadmap/webp/in/Stone 20.webp'),
      png: import('assets/images/roadmap/png/in/Stone 20.png')
    },
    stone1out: {
      webp: import('assets/images/roadmap/webp/out/Stone 1 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 1 out.png')
    },
    stone2out: {
      webp: import('assets/images/roadmap/webp/out/Stone 2 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 2 out.png')
    },
    stone3out: {
      webp: import('assets/images/roadmap/webp/out/Stone 3 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 3 out.png')
    },
    stone4out: {
      webp: import('assets/images/roadmap/webp/out/Stone 4 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 4 out.png')
    },
    stone5out: {
      webp: import('assets/images/roadmap/webp/out/Stone 5 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 5 out.png')
    },
    stone6out: {
      webp: import('assets/images/roadmap/webp/out/Stone 6 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 6 out.png')
    },
    stone7out: {
      webp: import('assets/images/roadmap/webp/out/Stone 7 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 7 out.png')
    },
    stone8out: {
      webp: import('assets/images/roadmap/webp/out/Stone 8 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 8 out.png')
    },
    stone9out: {
      webp: import('assets/images/roadmap/webp/out/Stone 9 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 9 out.png')
    },
    stone10out: {
      webp: import('assets/images/roadmap/webp/out/Stone 10 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 10 out.png')
    },
    stone11out: {
      webp: import('assets/images/roadmap/webp/out/Stone 11 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 11 out.png')
    },
    stone12out: {
      webp: import('assets/images/roadmap/webp/out/Stone 12 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 12 out.png')
    },
    stone13out: {
      webp: import('assets/images/roadmap/webp/out/Stone 13 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 13 out.png')
    },
    stone14out: {
      webp: import('assets/images/roadmap/webp/out/Stone 14 out.webp'),
      png: import('assets/images/roadmap/png/out/Stone 14 out.png')
    },
    branchBg: {
      webp: import('assets/images/contact/webp/owl-detail.webp'),
      png: import('assets/images/contact/png/owl-detail.png')
    }
  };

  return images;
}

export default getRoadmapImages;
