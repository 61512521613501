import React from 'react';

// eslint-disable-next-line no-undef
const webpTest = Modernizr.webp;

function useWebpSupport() {
  const [isWebpSupported, setIsWebpSupported] = React.useState(webpTest);

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    Modernizr.on('webp', (result) => {
      setIsWebpSupported(result.valueOf());
    });
  }, []);

  return isWebpSupported;
}

export default useWebpSupport;
