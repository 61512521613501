import React from 'react';
import {
  Routes, Route, BrowserRouter
} from 'react-router-dom';

import Home from 'components/pages/Home/Home';

import { useDispatch } from 'react-redux';
import { useLazyComponent } from 'hooks/useLazyComponent';
import { checkAuth } from 'redux/slices/adminSlice';

const WishModalContainerLazy = useLazyComponent(() => import('./containers/elements/WishModal/WishModalContainer'));
const AdminAuthLazy = useLazyComponent(() => import('./components/pages/AdminAuth/AdminAuth'));
const NotFoundLazy = useLazyComponent(() => import('./components/pages/NotFound/NotFound'));

export default function AppRoutes() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={(<Home />)} />
        <Route path="wishes" element={<WishModalContainerLazy />} />
        <Route path="admin" element={<AdminAuthLazy />} />
        <Route path="*" element={<NotFoundLazy />} />
      </Routes>
    </BrowserRouter>
  );
}
