import React from 'react';

import { Link } from 'react-scroll';
import HeaderSvgSelector from 'assets/images/icons/header/HeaderSvgSelector';
import Dropdown from 'components/UI/Dropdown/Dropdown';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { logout } from 'services/admin';
import { persistor } from 'redux/store';
import PortfolioButton from 'components/UI/PortfolioButton/PortfolioButton';
import Burger from 'components/elements/Burger/Burger';
import styles from './Header.module.scss';

function Header() {
  const sectionsId = ['home', 'about', 'portfolio', 'services', 'contact'];
  const isAuth = useSelector((state) => state.adminReducer.admin.isAuth);

  const [isBurgerActive, setIsBurgerActive] = React.useState(false);
  const overlay = React.useRef(null);
  const burgerPanelArea = React.useRef(null);

  const windowWidth = window.innerWidth;
  const offsetAbout = windowWidth > 1440 ? 80 : windowWidth > 1024 ? -80 : windowWidth > 768 ? -230 : 0;

  const mutateHook = useMutation(
    'admin logout',
    () => logout(),
    {
      onSuccess() {
        localStorage.removeItem('token');
        persistor.purge();
      },
      onError(error) {
        console.log('Admin logout error:', error);
      }
    }
  );

  function handleLogout() {
    mutateHook.mutate();
  }

  function openBurger() {
    if (!isBurgerActive) setIsBurgerActive(true);

    overlay.current.style.opacity = 0.5;
    overlay.current.style.visibility = 'visible';

    burgerPanelArea.current.style.visibility = 'visible';
  }

  return (
    <>
      <Burger isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} sectionsId={sectionsId} overlay={overlay} burgerPanelArea={burgerPanelArea} />
      <div className={styles.wrapper} id="home">
        <header className={styles.header}>
          <Link className={styles.logo} to="/">
            <HeaderSvgSelector id="logo" />
          </Link>
          <nav className={styles.nav}>
            {sectionsId.map((section) => (
              <Link
                className={styles['nav-elem']}
                to={section}
                spy
                smooth
                duration={1000}
                offset={section === 'about' ? offsetAbout : -80}
                key={section}
              >{section}
              </Link>
            ))}
          </nav>
          <div className={styles.language}>
            <HeaderSvgSelector id="language" fill="#1E1F2D" />
            <Dropdown />
          </div>
          <div
            onClick={openBurger}
            className={styles['burger-container']}
            style={{
              zIndex: isBurgerActive ? 2 : 3,
              visibility: !isBurgerActive ? 'visible' : 'hidden',
              opacity: !isBurgerActive ? '1' : '0'
            }}
          >
            <div className={styles.burger} />
          </div>
          {isAuth && (
            <PortfolioButton onClick={handleLogout} customClassName={styles.logout}>Logout</PortfolioButton>
          )}
        </header>
      </div>
    </>
  );
}

export default Header;
