import React from 'react';

import { useScroll } from 'framer-motion';

import getRoadmapImages from 'helpers/getRoadmapImages';
import useImages from 'hooks/useImages';
import useScrollPaths from 'hooks/useScrollPaths';
import useScrollParagraphs from 'hooks/useScrollParagraphs';

import Title from 'components/UI/Title/Title';
import RoadmapSvgSelector from 'assets/images/icons/roadmap/RoadmapSvgSelector';
import lampOn from 'assets/images/roadmap/png/lamps/Lamp on.png';
import lampOff from 'assets/images/roadmap/png/lamps/Lamp_off.png';

import Context from 'context';

import styles from './RoadmapPC.module.scss';

/* eslint-disable no-return-assign */

function RoadmapPC() {
  const refParent = React.useRef(null);
  const refLines = React.useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]);
  const refParagraphs = React.useRef([]);
  const { scrollYProgress } = useScroll({
    target: refParent,
    offset: ['start end', 'end end']
  });
  const [lampActive, setLampActive] = React.useState(false);
  const iconIds = ['first-line', 'second-line', 'third-line', 'fourth-line', 'fifth-line'];

  const images = getRoadmapImages();
  const { webp } = React.useContext(Context);
  const { imageSrcs, activateImage } = useImages(images, webp);
  const imgStyleOpacity = { opacity: activateImage ? 1 : 0 };

  useScrollPaths(refLines.current, scrollYProgress);
  useScrollParagraphs(scrollYProgress, refParagraphs.current, setLampActive, false);

  return (
    <div className={styles.roadmap} ref={refParent}>
      <div className={styles['title-wrapper']}>
        <p className={styles.desc}>our pipeline</p>
        <Title>RoadMap</Title>
      </div>
      <div className={styles.glow} />
      <div className={styles['stones-wrapper']}>
        <div className={styles.stone} id={styles.stone14out}>
          <img src={imageSrcs.stone14out} alt="stone14out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone4out}>
          <img src={imageSrcs.stone4out} alt="stone4out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone1}>
          <img src={imageSrcs.stone1} alt="stone1" style={imgStyleOpacity} loading="lazy" />
          <img className={styles.lamp} src={`${lampActive ? lampOn : lampOff}`} alt="roadmap lamp" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[0] = el)}
          >IDEA
          </p>
        </div>
        <div className={styles.stone} id={styles.stone2}>
          <img src={imageSrcs.stone2} alt="stone2" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.orange}`}
            ref={(el) => (refParagraphs.current[1] = el)}
          >PRE PRODUCTION
          </p>
        </div>
        <div className={styles.stone} id={styles.stone3}>
          <img src={imageSrcs.stone3} alt="stone3" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[2] = el)}
          >STORY
          </p>
        </div>
        <div className={styles.stone} id={styles.stone4}>
          <img src={imageSrcs.stone4} alt="stone4" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[3] = el)}
          >STORY<br />BOARD
          </p>
        </div>
        <div className={styles.stone} id={styles.stone5}>
          <img src={imageSrcs.stone5} alt="stone5" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[4] = el)}
          >ANIMATIC
          </p>
        </div>
        <div className={styles.stone} id={styles.stone6}>
          <img src={imageSrcs.stone6} alt="stone6" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[5] = el)}
          >CONCEPT<br />DESIGN
          </p>
        </div>
        <div className={styles.stone} id={styles.stone10out}>
          <img src={imageSrcs.stone10out} alt="stone10out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone7}>
          <img src={imageSrcs.stone7} alt="stone7" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.orange}`}
            ref={(el) => (refParagraphs.current[6] = el)}
          >PRODUCTION
          </p>
        </div>
        <div className={styles.stone} id={styles.stone8}>
          <img src={imageSrcs.stone8} alt="stone8" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[7] = el)}
          >LAYOUT
          </p>
        </div>
        <div className={styles.stone} id={styles.stone9}>
          <img src={imageSrcs.stone9} alt="stone9" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[8] = el)}
          >VFX
          </p>
        </div>
        <div className={styles.stone} id={styles.stone10}>
          <img src={imageSrcs.stone10} alt="stone10" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[9] = el)}
          >MODELING
          </p>
        </div>
        <div className={styles.stone} id={styles.stone11}>
          <img src={imageSrcs.stone11} alt="stone11" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[10] = el)}
          >LIGHTING
          </p>
        </div>
        <div className={styles.stone} id={styles.stone13out}>
          <img src={imageSrcs.stone13out} alt="stone13out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone12}>
          <img src={imageSrcs.stone12} alt="stone12" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[11] = el)}
          >TEXTURING
          </p>
        </div>
        <div className={styles.stone} id={styles.stone13}>
          <img src={imageSrcs.stone13} alt="stone13" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[12] = el)}
          >ANIMATION
          </p>
        </div>
        <div className={styles.stone} id={styles.stone14}>
          <img src={imageSrcs.stone14} alt="stone14" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[13] = el)}
          >RIGGING &<br />SETUP
          </p>
        </div>
        <div className={styles.stone} id={styles.stone15}>
          <img src={imageSrcs.stone15} alt="stone15" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[14] = el)}
          >RENDERING
          </p>
        </div>
        <div className={styles.stone} id={styles.stone18}>
          <img src={imageSrcs.stone18} alt="stone18" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[16] = el)}
          >COMPOSITING
          </p>
        </div>
        <div className={styles.stone} id={styles.stone16}>
          <img src={imageSrcs.stone16} alt="stone16" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.orange}`}
            ref={(el) => (refParagraphs.current[15] = el)}
          >POST PRODUCTION
          </p>
        </div>
        <div className={styles.stone} id={styles.stone17}>
          <img src={imageSrcs.stone17} alt="stone17" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[17] = el)}
          >3D VFX<br />MOTION
          </p>
        </div>
        <div className={styles.stone} id={styles.stone19}>
          <img src={imageSrcs.stone19} alt="stone19" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[18] = el)}
          >COLOR<br />CORRECTION
          </p>
        </div>
        <div className={styles.stone} id={styles.stone20}>
          <img src={imageSrcs.stone20} alt="stone20" style={imgStyleOpacity} loading="lazy" />
          <p
            className={`${styles.text} ${styles.white}`}
            ref={(el) => (refParagraphs.current[19] = el)}
          >FINAL OUTPUT
          </p>
        </div>
        <div className={styles.stone} id={styles.stone1out}>
          <img src={imageSrcs.stone1out} alt="stone1out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone2out}>
          <img src={imageSrcs.stone2out} alt="stone2out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone3out}>
          <img src={imageSrcs.stone3out} alt="stone3out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone5out}>
          <img src={imageSrcs.stone5out} alt="stone5out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone6out}>
          <img src={imageSrcs.stone6out} alt="stone6out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone7out}>
          <img src={imageSrcs.stone7out} alt="stone7out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone8out}>
          <img src={imageSrcs.stone8out} alt="stone8out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone9out}>
          <img src={imageSrcs.stone9out} alt="stone9out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone11out}>
          <img src={imageSrcs.stone11out} alt="stone11out" style={imgStyleOpacity} loading="lazy" />
        </div>
        <div className={styles.stone} id={styles.stone12out}>
          <img src={imageSrcs.stone12out} alt="stone12out" style={imgStyleOpacity} loading="lazy" />
        </div>
        {iconIds.map((iconId, index) => (
          <RoadmapSvgSelector iconId={iconId} ref={refLines.current[index]} id={styles[iconId]} key={iconId} />
        ))}
      </div>
    </div>
  );
}

export default RoadmapPC;
