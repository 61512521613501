import React from 'react';

import portfolioButtonProps from 'propTypes/PortfolioButton/portfolioButtonProps';
import portfolioButtonDefaultProps from 'propTypes/PortfolioButton/portfolioButtonDefaultProps';
import styles from './PortfolioButton.module.scss';

function PortfolioButton({ children, customClassName, onClick }) {
  const buttonStyles = !customClassName ? styles.button : `${styles.button} ${customClassName}`;

  return (
    <div className={buttonStyles} onClick={onClick}>
      {children}
    </div>
  );
}

PortfolioButton.propTypes = portfolioButtonProps;
PortfolioButton.defaultPropTypes = portfolioButtonDefaultProps;

export default PortfolioButton;
