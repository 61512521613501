import React from 'react';

import { useQuery } from 'react-query';
import { getAllProjects } from 'services/projects';
import PortfolioCarousel from 'components/elements/PortfolioCarousel/PortfolioCarousel';

function PortfolioCarouselContainer() {
  const { data } = useQuery('projects', () => getAllProjects(), { refetchOnWindowFocus: false });
  const projects = React.useMemo(() => data?.data || [], [data]);

  return (<PortfolioCarousel projects={projects} />);
}

export default PortfolioCarouselContainer;
