import React from 'react';

import ServicesSvgSelector from 'assets/images/icons/services/ServicesSvgSelector';

import { AnimatePresence, motion } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';

import HeroButton from 'components/UI/HeroButton/HeroButton';

import servicesMainMobileProps from 'propTypes/ServicesMainMobile/servicesMainMobileProps';
import servicesMainMobileDefaultProps from 'propTypes/ServicesMainMobile/servicesMainMobileDefaultProps';

import styles from './ServicesMainMobile.module.scss';

function ServicesMainMobile({
  customClassName, navElements, position, rightImgMotion, rightMotion, setPositionHandle
}) {
  const mainStyles = !customClassName ? styles.main : `${styles.main} ${customClassName}`;

  function swipeLeft() {
    if (position < navElements.length - 1) {
      setPositionHandle(position + 1);
    } else if (position === navElements.length - 1) {
      setPositionHandle(0);
    }
  }

  function swipeRight() {
    if (position > 0) {
      setPositionHandle(position - 1);
    } else if (position === 0) {
      setPositionHandle(navElements.length - 1);
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => swipeLeft(),
    onSwipedRight: () => swipeRight(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className={mainStyles}>
      <div className={styles.top}>
        <ServicesSvgSelector id={navElements[position].iconId} />
        <p className={styles.name}>
          {navElements[position].title}
        </p>
        <p className={styles.counter}>{`${position + 1}/${navElements.length}`}</p>
      </div>
      <div className={styles.bottom} {...handlers}>
        <div className={styles.icons}>
          {navElements.map((element, idx) => (
            <motion.div
              transition={rightMotion.transition}
              animate={{
                opacity: position === idx ? 1 : 0.4
              }}
              key={element.iconId}
              className={styles.icon}
              onClick={() => setPositionHandle(idx)}
            >
              <ServicesSvgSelector id={element.iconId} />
            </motion.div>
          ))}
        </div>
        <div className={styles.info}>
          <AnimatePresence>
            <motion.div
              key={`image-${position}`}
              initial={rightImgMotion.initial}
              animate={rightImgMotion.animate}
              exit={rightImgMotion.exit}
              transition={rightImgMotion.transition}
            >
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={navElements[position].img} className={styles['right-image']} />
            </motion.div>
            <motion.p
              className={styles.title}
              key={`title-${position}`}
              animate={rightMotion.animate}
              initial={rightMotion.initial}
              exit={rightMotion.exit}
              transition={rightMotion.transition}
            >{navElements[position].title}
            </motion.p>
            <motion.div
              className={styles['text-wrapper']}
              key={`text-${position}`}
              animate={rightMotion.animate}
              initial={rightMotion.initial}
              exit={rightMotion.exit}
              transition={rightMotion.transition}
            >{navElements[position].text}
            </motion.div>
          </AnimatePresence>
          <div className={styles.shadow} />
        </div>
      </div>
      <div className={styles['arrows-block']}>
        <HeroButton onClick={swipeRight} type="left" />
        <HeroButton onClick={swipeLeft} type="right" />
      </div>
    </div>
  );
}

ServicesMainMobile.propTypes = servicesMainMobileProps;
ServicesMainMobile.defaultPropTypes = servicesMainMobileDefaultProps;

export default ServicesMainMobile;
