import React from 'react';

import styles from './Glow.module.scss';

function Glow() {
  return (
    <div className={styles['glow-area']}>
      <div className={styles['glow-panel']}>
        <div className={styles.glow} />
      </div>
    </div>
  );
}

export default Glow;
