import React from 'react';

import Header from 'components/elements/Header/Header';
import Hero from 'components/elements/Hero/Hero';
import About from 'components/elements/About/About';
import Services from 'components/elements/Services/Services';
import Portfolio from 'components/elements/Portfolio/Portfolio';
import Roadmap from 'components/elements/Roadmap/Roadmap';
import Contact from 'components/elements/Contact/Contact';
import Footer from 'components/elements/Footer/Footer';
import StickySocials from 'components/elements/StickySocials/StickySocials';
import Glow from 'components/elements/Glow/Glow';

function Home() {
  return (
    <>
      <Glow />
      <StickySocials />
      <Header />
      <Hero />
      <About />
      <Services />
      <Portfolio />
      <Roadmap />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
