import React from 'react';

import selectorProps from 'propTypes/Selector/selectorProps';

import { ReactComponent as LeftArrow } from './left-arrow.svg';
import { ReactComponent as RightArrow } from './right-arrow.svg';

function HeroSvgSelector({ id, ...props }) {
  switch (id) {
  case 'left-arrow':
    return (<LeftArrow {...props} />);
  case 'right-arrow':
    return (<RightArrow {...props} />);

  default:
    return null;
  }
}

HeroSvgSelector.propTypes = selectorProps;

export default React.memo(HeroSvgSelector);
