import * as Yup from 'yup';

const schema = Yup.object().shape({
  fullName: Yup
    .string()
    .required('This field is required')
    .min(8, 'Full name must contain at least 8 characters')
    .max(64, 'Full name must be up to 64 characters long')
    .matches(/^[a-zA-Z]+([-'\s][a-zA-Z]+)*$/gm, 'The full name must contain only letters of the Latin alphabet, spaces between words, as well as - and \''),
  email: Yup
    .string()
    .required('This field is required')
    .email('Email address must be valid'),
  text: Yup
    .string()
    .required('This field is required')
    .min(16, 'Problem description field must contain at least 16 characters')
    .max(1024, 'Problem description field must be up to 1024 characters long')
    .matches(/^[a-zA-Z0-9 ,.;!@$#%()]+$/gm, 'Problem description field must contain only letters of the Latin alphabet, numbers and some characters')
});

export default schema;
